import { FormattedMessage } from "locale/langUtils";
import CurrencyFormat from "locale/CurrencyFormat";
import DateTimeFormat from "locale/DateTimeFormat";
import { map } from "lodash-es";
import { VarekjopWSticker } from "./ReceiptPage";
import { PaymentDetail } from "./PaymentDetail";
import { BYMAddress } from "./BYMAddress";
import { Kvitteringsnummer } from "./Kvitteringsnummer";
import { PURCHASE_STATES } from "appConstants";

interface ReceiptPrintContentProps {
  paymentDetails: PaymentDetail[];
  varekjop: VarekjopWSticker;
  oblattyper: any;
  kjoretoy: any;
  className?: string;
}
export function ReceiptPrintContent({
  varekjop,
  oblattyper,
  kjoretoy,
  paymentDetails,
  className
}: ReceiptPrintContentProps) {
  return (
    <div>
      <BYMAddress className="kvittering-print-info" />
      <Kvitteringsnummer className="kvittering-print-header" kvitteringsnummer={varekjop?.kjopsnummer} />
      <div className={className}>
        <table className="kvittering-print-table-borderless">
          <thead>
            <tr className="text-6">
              <th>
                <FormattedMessage id="receiptpage.receiptThregnumber" />
              </th>
              <th>
                <FormattedMessage id="receiptpage.receiptThType" />
              </th>
              <th>
                <FormattedMessage id="receiptpage.receiptThValidFrom" />
              </th>
              <th className="right-align">
                <FormattedMessage id="receiptpage.receiptThPrice" />
              </th>
            </tr>
          </thead>
          <tbody>
            {varekjop?.oblater &&
              oblattyper &&
              kjoretoy &&
              map(varekjop?.oblater, oblat => {
                return (
                  <tr key={oblat.oblat.id} className="ordrelinje-print-row">
                    <td className="kjoretoybeskrivelse pr-2">
                      {oblat.oblat.kjoretoy
                        ? `${oblat.oblat.kjoretoy.registreringsnummer}   ${
                            oblat.oblat.kjoretoy.beskrivelse ? oblat.oblat.kjoretoy.beskrivelse : ""
                          }`
                        : ""}
                    </td>
                    <td className="pr-2">
                      <FormattedMessage id={oblat.name} defaultMessage="Ukjent oblattype" />
                    </td>
                    <td className="pr-2">
                      <DateTimeFormat value={oblat.oblat.gyldigFra} />
                      {` - `}
                      <DateTimeFormat value={oblat.oblat.gyldigTil} />
                    </td>
                    <td className="right-align p-0">
                      <CurrencyFormat value={oblat.oblat.oblattype?.pris ?? 0} />
                    </td>
                  </tr>
                );
              })}
            <tr className="total-amount-row">
              <td className="right-align pt-2" colSpan={4}>
                <FormattedMessage
                  id="receiptpage.receiptTableFooterSum"
                  values={{
                    sum: <CurrencyFormat value={varekjop?.totalsum ?? 0} />
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="kvittering-print-info">
        <strong>
          <FormattedMessage id="receiptpage.details" />
        </strong>
        {paymentDetails.map((d, i) => (
          <span key={i} className="kvittering-betalingsdetalj mb-1">
            {d.label}{" "}
            {d.value ? (
              <>
                {`: `}
                {d.value}
              </>
            ) : (
              ""
            )}
          </span>
        ))}
      </div>
      {varekjop?.betalingsform.toLocaleUpperCase() === "FAKTURA" &&
        varekjop.statusId === PURCHASE_STATES.INVOICE_SENT && (
          <FormattedMessage id="receiptpage.stickersValidNotification" />
        )}
    </div>
  );
}
